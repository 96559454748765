import styled, { css } from "styled-components";

import { container } from "../../styles/GlobalStyles";
import { device, mediaQuery } from "../../styles/mixins";

export const AboutStyles = styled.section`
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  font-family: "Inter", sans-serif;
  margin-bottom: 48px;

  ul {
    margin-left: 24px;
  }
  .container {
    ${container}
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative; // Ensure the container can handle the floated elements
    
    .introduction {
      max-width: 942px;
      margin: 0 auto;
      flex: 1 1 45%;
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .video {
      margin-left: auto;
      margin-right: auto;
      margin-top: 48px;
      flex-shrink: 0;
      max-width: 560px;
      width: 100%;
      align-self: flex-start; // Align video with text
      float: right;  // Ensure the video is floated so text can wrap around
      margin-left: 24px; // Space between text and video
    }
    ${mediaQuery.lg`
      flex-direction: row;
      gap: 24px;

      .video {
        // margin-top: 100px;
        flex: 1 0 41%;
      }
  `}

    h2 {
      color: var(--color__primary);
      font-size: 32px;
      margin: 2rem 0 1.5rem;
      position: relative;
      line-height: 1.2;
      font-weight: 600;
      flex-basis: 100%;
      margin-left: 14px;
      text-align: center;

      ${mediaQuery.lg`
            font-size: 2.5rem;
        margin-bottom: 0;
        text-align: left;
      `}
    }

    p {
      font-size: 1.2rem;
      margin-top: 1rem;
      line-height: 1.5;
      text-align: center;
      ${mediaQuery.md`
        text-align: left;
      `}
    }

    b {
      font-weight: 600;
    }
  }
`;

export const VideoContainer = styled.div`
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  height: 315px;
  align-self: center;
  box-shadow: rgb(0 0 0 / 3%) 0px 1px 2px, rgb(0 0 0 / 3%) 0px 2px 4px,
    rgb(0 0 0 / 3%) 0px 4px 8px, rgb(0 0 0 / 3%) 0px 8px 16px;
`;
