import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Select from "react-select";
import Modal from "../UI/Modal";
import {
  ContactStyles,
  ContactInfo,
  ContactSeparator,
  Button,
  Text,
  ContactForm,
  Fieldset,
  Label,
  Input,
  Telephone,
  LinkedIn,
  // ContactIcons,
} from "./Contact.styled";

// This setups the contact form
export default function Contact() {
  const [isFormFocused, setFormFocused] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  
  const [message, setMessage] = useState("");
  const [sinister, setSinister] = useState(null);
  const [damageType, setDamageType] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isError, setIsError] = useState(false);

  // Sends the data by an URL
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  // Handle the submission of the form and the encoding.
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        name,
        email,
        telephone,
        sinister,
        damageType,
        contact_message: message,
      }),
    })
      .then(() => {
          setIsReady(true); // Set the form ready state
          alert("Solicitud de consulta enviada"); // Show the alert after the form is ready
      })
      .catch((error) => {
          console.error(error); // Log the error
          setIsError(true); // Set error state
          setIsReady(true); // Ensure the modal is shown even if there's an error
      });
  };

  // Toggles the form when it's focused, think it as a flag.
  const handleFormFocus = () => {
    setFormFocused((previousState) => !previousState);
  };

  // Resets the modal once the user closes it
  const resetModal = () => {
    setIsReady(false);
    setIsError(false);
  };
  
 // All types of damage are defined here.
  const damageOptions = {
    Hogar: ["Inundaciones", "Incendios", "Eléctrico", "Fugas de gas", "Robos o vandalismo", "Plagas", "Fenómenos atmosféricos", "Piscinas & Jacuzzis", "Obras cercanas", "Otros"],
    Automovil: ["Colisiones", "Atropellos", "Robo Vehículo", "Fenómenos naturales", "Otros"],
    Agrario: ["Granizo", "Heladas", "Inundaciones", "Sequía", "Incendios", "Ganadería", "Daño infraestructuras", "Otros"],
    "Devolución de gastos hipotecarios": ["Seguro de vida", "Seguro de hogar", "Seguro de responsabilidad civil", "Seguro de desempleo", "Seguro de proteccion de pagos", "Gastos legales"],   
    Otros: ["Otros"],
  };

  // Renders the form and displays it
  const isFormDirty = email || name || message || sinister || damageType || telephone;
  return (
    <section style={{ marginBottom: "48px" }} id="contact">
      <ContactStyles
        className="container"
        isFormFocused={isFormFocused || isFormDirty}
      >
        {/* Contact information to the left just above the footer*/}
        <ContactInfo>
          <h2>Contacto</h2>
          <ContactSeparator />
          <Text>
            Programe una reunión y permítanos ayudarlo. <br />{" "}
            <Telephone href="tel:+11111111111">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fad"
                data-icon="phone-square-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-phone-square-alt fa-w-14 fa-3x"
              >
                <g className="fa-group">
                  <path
                    fill="rgb(232,237,234)"
                    d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-16.38 307.37l-15 65A15 15 0 0 1 354 416C194 416 64 286.29 64 126a15 15 0 0 1 11.63-14.61l65-15a15 15 0 0 1 17.16 8.7l30 70a15 15 0 0 1-4.29 17.52l-37.89 31A231.94 231.94 0 0 0 256.4 334.39l31-37.89a15 15 0 0 1 17.52-4.29l70 30a15 15 0 0 1 8.7 17.16z"
                    className="fa-secondary"
                  ></path>
                  <path
                    fill="var(--color__primary-alt)"
                    d="M383.62 339.37l-15 65A15 15 0 0 1 354 416C193.94 416 64 286.29 64 126a15 15 0 0 1 11.63-14.61l65-15a15 15 0 0 1 17.16 8.7l30 70a15 15 0 0 1-4.29 17.52l-37.89 31a231.92 231.92 0 0 0 110.78 110.78l31-37.89a15 15 0 0 1 17.52-4.29l70 30a15 15 0 0 1 8.71 17.16z"
                    className="fa-primary"
                  ></path>
                </g>
              </svg>{" "}
              +1 (111) 111-1111
            </Telephone>
            <LinkedIn
              href="https://www.linkedin.com/company/mataseguros/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="linkedin"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-linkedin fa-w-14 fa-3x"
              >
                <path
                  fill="currentColor"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                  className=""
                ></path>
              </svg>
              @mataseguros
            </LinkedIn>
            <LinkedIn
              href="https://www.youtube.com/mataseguros"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="youtube"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                className="svg-inline--fa fa-youtube fa-w-18 fa-3x"
              >
                <path
                  fill="currentColor"
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                  className=""
                ></path>
              </svg>
              Mataseguros
            </LinkedIn>
          </Text>
          {/* Social?? */}
          {/* <ContactIcons /> */}
        </ContactInfo>
        {/* Here ends the Contact information */}
        {/* Deals and renders the contact form */}
        <ContactForm isFormFocused={isFormFocused || isFormDirty}>
          <form
            netlify-honeypot="bot-field"
            netlify="true"
            name="contact"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <Fieldset>
              <Label htmlFor="firstName">Nombre</Label>
              <Input
                type="text"
                name="name"
                id="firstName"
                onFocus={() => setFormFocused(true)}
                onBlur={handleFormFocus}
                required
                onChange={(event) => setName(event.target.value)}
              />
            </Fieldset>
            {/* Email of the client */}
            <Fieldset>
              <Label htmlFor="email">Email</Label>
              <Input
                name="email"
                type="email"
                id="email"
                onFocus={() => setFormFocused(true)}
                onBlur={handleFormFocus}
                required
                onChange={(event) => setEmail(event.target.value)}
              />
            </Fieldset>
            {/* Telephone of the client */}
            <Fieldset>
              <Label htmlFor="email">Teléfono</Label>
              <Input
                name="telephone"
                type="telephone"
                id="telephone"
                onFocus={() => setFormFocused(true)}
                onBlur={handleFormFocus}
                required
                onChange={(event) => setTelephone(event.target.value)}
              />
            </Fieldset>
            {/* Asks the potential client their problems */}
            <Fieldset>
              <Label htmlFor="sinister">Tipo de incidencia</Label>
              <Select
                name="sinister"
                id="sinister"
                required
                placeholder="Tipo de incidencia"
                options={[
                  { value: "Hogar", label: "Hogar" },
                  { value: "Automovil", label: "Automovil" },
                  { value: "Agrario", label: "Agrario" },
                  { value: "Devolución de gastos hipotecarios", label: "Devolución de gastos hipotecarios" },
                  { value: "Otros", label: "Otros" }
                ]}
                onChange={(value) => {
                  setSinister(value.label); // Pon el valor seleccionado
                  setDamageType(null);      // Resetea el valor del daño específico
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "rgb(232, 237, 234)",
                    primary: "var(--color__primary)",
                  },
                })}
              />
            </Fieldset>
            {/* This is another dropdown menu that only appears when the the first one is selected. */}
            {sinister && (
              <Fieldset>
                <Label htmlFor="damageType">Tipo de Daño</Label>
                <Select
                  name="damageType"
                  id="damageType"
                  required
                  placeholder="Elige un tipo de daño..."
                  value={damageType ? { value: damageType, label: damageType } : null}  // Bind to state
                  options={damageOptions[sinister]?.map((damage) => ({
                    value: damage,
                    label: damage,
                  })) || []}  // Handle undefined case
                  onChange={(value) => setDamageType(value.value)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "rgb(232, 237, 234)",
                      primary: "var(--color__primary)",
                    },
                  })}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Label htmlFor="contact_message">Mensaje (opcional)</Label>
              <textarea
                name="contact_message"
                rows="6"
                cols="50"
                onFocus={() => setFormFocused(true)}
                onBlur={handleFormFocus}
                onChange={(event) => setMessage(event.target.value)}
              />
            </Fieldset>
            <div data-netlify-captcha></div>

            <Button isFormFocused={isFormFocused || isFormDirty}>
              Mandar mensaje
            </Button>
          </form>
        </ContactForm>
      </ContactStyles>
      {isReady && <Modal onClose={resetModal} isError={isError} />}
    </section>
  );
}
